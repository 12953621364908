import React from 'react';
import Accessibility from '../components/Accessibility';
import ScrollToTop from '../components/ScrollToTop';

function AccessibilityPage() {
  return (
    <>
      <ScrollToTop />
      <Accessibility />
    </>
  );
}

export default AccessibilityPage;
