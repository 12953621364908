import styled from "styled-components";

export const MobileContainer = styled.div`
    display: none;
    background-color: #0075c4;
    justify-content: center;
    align-items: flex-end;

    @media screen and (max-width: 420px) and (max-height: 750px) {
    display: flex;
}

`;

export const TextWrapper = styled.div`
    display: flex;
    width: 90%;
    padding-top: 12%;
    padding-bottom: 8%;
`;

export const Text = styled.p`
    color: #fff;
    font-style: italic;
`;