import React from 'react'
import Jouni from '../../images/profilepictures/Jouni.png'
import Tiina from '../../images/profilepictures/TiinaRound.png'
import Mart from '../../images/profilepictures/MartRound.png'
import Ghida from '../../images/profilepictures/Ghida.png'
import Jens from '../../images/profilepictures/JensRound.png'

import {
  StaffContainer,
  StaffH1,
  StaffP,
  StaffPBox,
  StaffPic,
  StaffWrapper,
  StaffPHeading,
  StaffPtext,
  ContactInfoWrapper,
  ContactInfoBox,
  ContactInfo,
  EmailContact
} from './StaffElements';


function Staff() {
  return (
    <StaffContainer id ='staff'>
        <StaffH1>Course Staff and Contacts</StaffH1>
        <StaffWrapper>
          <StaffPBox>
            <StaffPic>
              <img src={Jouni} width='100%' alt='Jouni'/>
            </StaffPic>
            <StaffP>
            <StaffPHeading>
                <b>Jouni Lounasmaa</b> - Teacher <br/> MSc (Tech), MBA
                </StaffPHeading>
              <StaffPtext>
              Jouni is a former startup executive currently working at the New Children's Hospital Foundation
              </StaffPtext>
            </StaffP>
          </StaffPBox>

          <StaffPBox>
            <StaffPic>
            <img src={Mart} width='100%' alt='Märt'/>
            </StaffPic>
            <StaffP>
            <StaffPHeading>
                <b>Märt Vesinurm</b> - Teacher
                </StaffPHeading>
              <StaffPtext>
              Märt Vesinurm is a doctoral candidate in the IEM department at Aalto University
              </StaffPtext>
            </StaffP>
          </StaffPBox>

          <StaffPBox>
            <StaffPic>
            <img src={Ghida} width='100%' alt='Ghida'/>
            </StaffPic>
            <StaffP>
              <StaffPHeading>
                <b>Ghida Khalife</b> - Course assistant
                </StaffPHeading>
              <StaffPtext>
              Ghida Khalife is in her second year of pursuing a Master's degree in Translational Medicine at University of Helsinki.
              </StaffPtext>
            </StaffP>
          </StaffPBox>

          <StaffPBox>
            <StaffPic>
            <img src={Jens} width='100%' alt='Jens'/>
            </StaffPic>
            <StaffP>
              <StaffPHeading>
                <b>Jens Schmidt</b> - Program director, <br/> Aalto University
                </StaffPHeading>
              <StaffPtext>
              Jens Schmidt is a strategy professor at Aalto University, currently working as the head of the department of IEM
              </StaffPtext>
            </StaffP>
          </StaffPBox>

          <StaffPBox>
            <StaffPic>
            <img src={Tiina} width='100%' alt='Tiina'/>
            </StaffPic>
            <StaffP>
            <StaffPHeading>
                <b>Tiina Immonen</b> - Program director, <br/> Translational Medicine
                </StaffPHeading>
              <StaffPtext>
              Tiina Immonen works as a lecturer in the Faculty of Medicine at University of Helsinki
              </StaffPtext>
            </StaffP>
          </StaffPBox>   

          <ContactInfoWrapper>
            <ContactInfoBox>
              <ContactInfo>
                For contact and inquiries, please use the following email address:
              </ContactInfo>
              <EmailContact>
              healthtech-linkage [at] aalto.fi
              </EmailContact>
            </ContactInfoBox>
          </ContactInfoWrapper>

          </StaffWrapper>
    </StaffContainer>
  )
}

export default Staff