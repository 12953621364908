import React from 'react';
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SidebarItem,
} from './SidebarElements';

import { animateScroll as scroll } from 'react-scroll';

const Sidebar = ({ isOpen, toggle }) => {

  const toggleHome = () => {
    scroll.scrollToTop()
    ;
  };

  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
         {/* <SidebarLink
            to='/'
            onClick={toggleHome}
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-80}
          >
            Home
          </SidebarLink>  */}
          <SidebarItem>
          <SidebarLink
            to='about'
            onClick={toggle}
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-80}
          >
            About the Course
          </SidebarLink>
          </SidebarItem>
          <SidebarItem>
          <SidebarLink
            to='application'
            onClick={toggle}
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-80}
          >
            Application
          </SidebarLink>
          </SidebarItem>
          <SidebarItem>
          <SidebarLink
            to='alumni'
            onClick={toggle}
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-80}
          >
            Alumni
          </SidebarLink>
          </SidebarItem>
          <SidebarItem>
          <SidebarLink
            to='staff'
            onClick={toggle}
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-80}
          >
            Staff
          </SidebarLink>
          </SidebarItem>
        </SidebarMenu>
        
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
