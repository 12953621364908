import React, { useState } from 'react'
import { SliderData } from './SliderData';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

import {
  AlumniContainer,
  AlumniContent,
  AlumniH1,
  AlumniP,
  AlumniPBox,
  AlumniH2,
  AlumniContentWrapper,
  QuoteSlider,
  QuoteContentWrapper,
  ScrollLeft,
  ScrollRight,
  QuoteDescription,
  AlumniName
} from './AlumniElements';


const Alumni = ({slides, quote, alumniname }) => {
  const [current, setCurrent] = useState(0);
  const length = slides.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }


  return (
    <AlumniContainer id ='alumni'>
      <AlumniContent>
        <AlumniH1>Course Alumni</AlumniH1>
        <AlumniContentWrapper className='Alumniwrapper'>
          <AlumniPBox>
            <AlumniH2>Backgrounds of the participants include:</AlumniH2>
            <AlumniP>
                Computer science
            </AlumniP>
            <AlumniP>
                Translational Medicine
            </AlumniP>
            <AlumniP>
                Economics
            </AlumniP>
            <AlumniP>
                Medicine
            </AlumniP>
            <AlumniP>
                Visual Cultures, Curating, and Contemporary Art
            </AlumniP>
            <AlumniP>
                Life Science Informatics
            </AlumniP>
            <AlumniP>
                Life Science Technologies:
                 <ul>
                 <li>Bioinformatics and Digital Health</li>
                 <li>Biomedical Engineering</li>
                 <li>Biosystems and Biomaterials</li>
                 <li>Complex Systems</li>
                 </ul>
            </AlumniP>
            <AlumniP>
                Automation and Electrical Engineering
            </AlumniP>
            <AlumniP>
                Neuroscience and Physiology
            </AlumniP>
            <AlumniP>
                Industrial Engineering and Management
            </AlumniP>
            <AlumniP>
                Mechanical Engineering
            </AlumniP>
            <AlumniH2>Not on the list? <br/>Apply and diversify the alumni network!</AlumniH2>
          </AlumniPBox>
            
            

            <QuoteSlider>
      <ScrollLeft> <FaAngleLeft onClick={prevSlide} /> </ScrollLeft>
      <ScrollRight> <FaAngleRight onClick={nextSlide} /> </ScrollRight>
      {SliderData.map((slide, index) => {
        return (
          <QuoteContentWrapper
            className={index === current ? 'slide active' : 'slide'}
            key={index}
          > 
            {index === current && (
              <img src={slide.image} alt={slide.alt} className='image' />
              
            )}
            <QuoteDescription className="quote">{
            index === current && (slide.quote)}</QuoteDescription>
            <AlumniName className="name">{index === current && (slide.alumniname)}</AlumniName>
          </QuoteContentWrapper>
        );
      })}
    </QuoteSlider>

          
          </AlumniContentWrapper>
      </AlumniContent>
    </AlumniContainer>
  )
}

export default Alumni;