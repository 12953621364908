import React from 'react'

import { 
    MobileContainer,
    TextWrapper,
    Text

} from './MobileAboutElements'

const MobileAbout = () => {
  return (
    <MobileContainer>
        <TextWrapper>
            <Text>
            Take a deep-dive into the HealthTech industry with visiting company guests, attend exciting excursions, work in multidisciplinary teams of students, solve innovation challenges, and network with like-minded students and experts from the industry. <br/> <br/>
        <b>Info recording </b><ul><li>http://bit.ly/healthtechlinkage2023</li></ul>
            </Text>
        </TextWrapper>
    </MobileContainer>
  )
}

export default MobileAbout