import React from "react";

import {
  AboutContainer,
  AboutH1,
  AboutWrapper,
  AboutCard,
  AboutP,
  TextBoxL,
  TextBoxR
} from "./AboutElements";

const About = () => {
  return (
    <AboutContainer id="about">
      <AboutH1>About the Course</AboutH1>
      <AboutWrapper>
        <AboutCard>
          <TextBoxL>
            <AboutP>
              Take the opportunity to deepen your knowledge about innovation and the commercialization of new technologies and business models in the HealthTech field. Learn about the health care system, medical device technologies, digital medicine and health & well-being services. 
              <br />
              <br />
              The course is open to all master’s level students from Aalto
              University and University of Helsinki, worth 10 ECTS credits, and limited to 20 participants. The course is set to run from mid-January to mid-April.
            </AboutP>
          </TextBoxL>
        </AboutCard>

        <AboutCard>
          <TextBoxR>
            <AboutP>
            Healthtech Linkage is designed to serve as a community building platform, linking together students from diverse backgrounds, who share the same interests and aspirations, with professionals from every corner of the healthtech industry.
              <br />
              <br />
              By attending the guest lectures and excursions, you will get to know companies, organizations and individuals from the field. In previous years during the course students met people who shared insights from the following companies, among others: Apple, Oura, Orion, Terveystalo, Planmeca, Bayer, Nordic Healthcare Group, Veri, and Blueprint Genetics.
            </AboutP>
          </TextBoxR>
        </AboutCard>

        <AboutCard>
          <TextBoxL>
            <AboutP>
              The course consists of weekly lectures, frequently held by
              professionals from the industry, and two innovation challenges.
              <br />
              <br />
              In the innovation challenges solutions are built in multidiscipinary teams formed from
              the course participants. The teams will
              finally pitch their solutions in front of a jury, with the best
              solutions being awarded.
              <br />
              <br />
              <b>This course is a highly intense but rewarding experience!</b>
            </AboutP>
          </TextBoxL>
        </AboutCard>

      </AboutWrapper>
    </AboutContainer>
  );
};

export default About;
