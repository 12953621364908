import React from 'react';
import Icon1 from '../../images/studies.svg';
import Icon2 from '../../images/motivation.svg';
import Icon3 from '../../images/diversity.svg';
import {
  ApplicationContainer,
  ApplicationH1,
  ApplicationWrapper,
  ApplicationCard,
  ApplicationIcon,
  ApplicationH2,
  ApplicationP,
  ApplicationH3,
  ApplicationDesc,
  InfoWrapper,
  ApplicationEmailWrap,
  ApplicationEmailText,
  ApplicationEmailAddress,
  ApplicationDetails
} from './ApplicationElements';

const Application = () => {
  return (
     <ApplicationContainer id='application'> {/* id='Application' */}
      <ApplicationH1>Participants are selected 
        <br/>
        based on the following criteria:</ApplicationH1>
      <ApplicationWrapper>
        <ApplicationCard>
          <ApplicationIcon src={Icon1} alt='studies icon'/>
          <ApplicationH2>Prior Studies</ApplicationH2>
          <ApplicationP>
            Please submit a transcript of study records for both your bachelor
            and current master's studies.
          </ApplicationP>
        </ApplicationCard>
        <ApplicationCard>
          <ApplicationIcon src={Icon2} alt='motivation icon'/>
          <ApplicationH2>Motivation</ApplicationH2>
          <ApplicationP>
            Tell us why you want to take part in the program. Please submit a motivation letter, see below.
          </ApplicationP>
        </ApplicationCard>
        <ApplicationCard>
          <ApplicationIcon src={Icon3} alt='diversity icon'/>
          <ApplicationH2>Diversity</ApplicationH2>
          <ApplicationP>
            We want to have a good mix of students from different backgrounds.
            <br/>
            <br/>
          </ApplicationP>
        </ApplicationCard>
      </ApplicationWrapper>
      <InfoWrapper>
        <ApplicationH3><b>As part of your application, please submit a motivation letter of max. 500 words, which answers the following three questions:</b>
        </ApplicationH3>
        <ApplicationDetails>
        <ApplicationDesc><ul><li>What relevant experience do you have, also outside of academic studies?</li></ul>
</ApplicationDesc>
<ApplicationDesc><ul><li>What do you hope to learn and get out of the course?</li></ul>
</ApplicationDesc>
<ApplicationDesc><ul><li>What specific domain in the HealthTech field are you particularly interested in and why?</li></ul>
</ApplicationDesc>
</ApplicationDetails>
</InfoWrapper>

<InfoWrapper>
  <ApplicationEmailWrap>
    <ApplicationEmailText>
      Please send your study transcripts and the motivation letter to the email adress:
    </ApplicationEmailText>
    <ApplicationEmailAddress>  healthtech-linkage [at] aalto.fi </ApplicationEmailAddress>
  </ApplicationEmailWrap>
</InfoWrapper>

<InfoWrapper>
<ApplicationH3><b>Important dates</b>
        </ApplicationH3>
        <ApplicationDetails>
        <ApplicationDesc><b>Monday 23.10.2023 at 16:15</b> - Zoom link: <ul><li>https://helsinki.zoom.us/j/64356291741?pwd=Ykpjc0h0SlRMdXhHVUcwblBZdTlidz09</li></ul> 
</ApplicationDesc>
<ApplicationDesc><b>Wednesday 15.11.2023 </b>- Application deadline.
</ApplicationDesc>
<ApplicationDesc><b>Early December</b>- Applicants are informed of the selection. Selected participants will be informed on how to register for the course in Sisu.
</ApplicationDesc>
<ApplicationDesc><b>16.01.2024</b> - Course kickoff event. 
</ApplicationDesc>
</ApplicationDetails>
</InfoWrapper>
    </ApplicationContainer>
  );
};

export default Application;
