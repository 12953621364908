import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import {
  FooterContainer,
  FooterWrap,
  AccessibilitySection,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  LogoImage,
  HTLogo,
  NavBtnLink

} from './FooterElements';

import FooterLogo from '../../images/HugeLogo.png';
import UniLogo from '../../images/Collab.png';



const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };
  return (
    <FooterContainer>
      <FooterWrap>
        <SocialMedia>
          <AccessibilitySection>
          <NavBtnLink to='/accessibility'>Accessibility statement</NavBtnLink>
          </AccessibilitySection>
          <SocialMediaWrap>
            <SocialLogo to='/' onClick={toggleHome}>
              <HTLogo src={FooterLogo} alt='HealthTech Linkage logo'/>
            </SocialLogo>
            <LogoImage src={UniLogo} alt='Aalto University and University of Helsinki logos'/>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
