import styled from 'styled-components';

import { Link as LinkS } from 'react-scroll';
import { Link as LinkR } from 'react-router-dom';

export const FooterContainer = styled.footer`
  background-color: #fff;
`;

export const FooterWrap = styled.div`
  padding: 48px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
`;

export const AccessibilitySection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 2% 0;
`;

export const NavBtnLink = styled(LinkR)`
  white-space: nowrap;
  padding: 10px 22px;
  color: #000;
  font-size: 1rem;
  transition: all 0.2s ease-in-out;
  text-decoration: underline;
`;

export const SocialMedia = styled.section`
  max-width: 1000px;
  width: 100%;
`;

export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1100px;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const SocialLogo = styled(LinkS)`
  cursor: pointer;
  display: flex;
  max-width: 30%;
  

  @media screen and (max-width: 900px) {
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  @media screen and (max-width: 480px){
    max-width: 50%;
    margin-top: 0;
  }
`;

export const HTLogo = styled.img`
width: 100%;
padding: 0 14% 2% 0;

@media screen and (max-width: 820px){
  padding: 0;
}

`;

//Aalto & Helsinki logo
export const LogoImage = styled.img`
 width: 45%;

 @media screen and (max-width: 480px){
  width: 80%;
 }
`;