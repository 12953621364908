import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection';
import Staff from '../components/Staff';
import Application from '../components/Application';
import Alumni from '../components/Alumni';
import About from '../components/About';
import MobileAbout from '../components/MobileAbout';
import { SliderData } from '../components/Alumni/SliderData'

function Home() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <MobileAbout />
      <About />
      <Application />
      <Alumni slides={SliderData} />
      <Staff />
      <Footer />
    </>
  );
}

export default Home;
