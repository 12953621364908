import React from 'react';
import Logos from '../../images/Collab.png';
import SVG from '../../images/HomeEdited.svg';

import {
  HeroContainer,
  HeroContent,
  HeroH1,
  HeroP,
  HeroPBox,
  HeroFooter,
  FooterImage,
  HeroRow,
  Column1,
  Column2,
  Img,
  TextWrapper,
  UniImg,
  MobileH1,
  SVGWrapper,
  MiniP
} from './HeroElements';

function HeroSection() {
  return (
    <HeroContainer id='home'>
      <HeroContent>
        <HeroRow>
          <Column1>
          <MobileH1>HealthTech Linkage</MobileH1>
          <SVGWrapper>
          <Img src={SVG} alt="HealthTech Linkage" />
          </SVGWrapper>
          </Column1>
          <Column2>
          <TextWrapper>
          <HeroH1>HealthTech Linkage</HeroH1>
          <HeroPBox>
        <HeroP>
        Take a deep-dive into the HealthTech industry with visiting company guests, 
        attend exciting excursions, work in multidisciplinary teams of students, 
        solve innovation challenges, and network with like-minded students and 
        experts from the industry.
        </HeroP>
        <HeroP>
        <b>Info recording download</b><ul><li>http://bit.ly/healthtechlinkage2023</li></ul>

        </HeroP>
        </HeroPBox>
          </TextWrapper>
          </Column2>
        </HeroRow>
        <HeroFooter>
          <FooterImage>
            <MiniP>A joint course by</MiniP>
            <UniImg src={Logos} alt='Aalto University and University of Helsinki logos'/>
            </FooterImage>
        </HeroFooter>
      </HeroContent>
      
    </HeroContainer>
  );
}

export default HeroSection;
